export default {
  okText: '確定',
  closeText: '關閉',
  cancelText: '取消',
  loadingText: '加載中...',
  saveText: '保持',
  delText: '刪除',
  resetText: '重置',
  searchText: '搜索',
  queryText: '立即查詢',
  nextText: '下一步',

  inputText: '請輸入',
  chooseText: '請選擇',

  add: '添加',
  update: '更新',
  refresh: '刷新',
  back: '返回',

  light: '亮色主題',
  dark: '黑暗主題',
  countdown: {
    normalText: '獲取驗證碼',
    sendText: '{0}秒後重新獲取',
  },
};
